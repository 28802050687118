"use client";

import NextLink from "next/link";
import { useTheme } from "next-themes";
import { Link } from "@nextui-org/link";
import {
  InstaLogo,
  LinkdinLogo,
  TelegramLogo,
  TwitterIcon,
  WhatsappLogo,
} from "./icons";
import { Input, Textarea } from "@nextui-org/input";
import { Button } from "@nextui-org/button";
import { useForm } from "react-hook-form";
import { useMediaQuery } from "react-responsive";
import {
  FaLinkedin,
  FaInstagram,
  FaFacebook,
  FaTelegram,
} from "react-icons/fa";
import { backendUri } from "@/config/config";

export const Footer = () => {
  const { register, handleSubmit } = useForm();
  const { theme } = useTheme();
  const isfooterScreen = useMediaQuery({ query: "(max-width: 400px)" });
  const isSmallfooterScreen = useMediaQuery({ query: "(max-width: 320px)" });
  const onSubmit = async (data: any) => {
    // WhatsApp message
    const phoneNumber = "+919699033291";
    const Msg = `Hello KonnectSquare Team,

My name is ${data?.fullName}. I am reaching out from the konnectsquare.com website with a quick inquiry.

Message :- ${data?.message}`;

    const whatsappLink = `https://web.whatsapp.com/send?phone=${phoneNumber}&text=${encodeURIComponent(
      Msg
    )}`;

    // Open WhatsApp
    if (window?.top) {
      window.open(whatsappLink, "_blank");
    } else {
      console.error("window.top is not defined");
    }

    // Send Email via Backend
    try {
      const response = await fetch(
        `${backendUri}/api/v1/common/contact`,
        {
          // Update with your actual backend URL
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );

      if (response.ok) {
        alert("Your message has been sent successfully via Email & WhatsApp!");
      } else {
        alert("Failed to send email.");
      }
    } catch (error) {
      console.error("Error:", error);
      alert("Something went wrong.");
    }
  };

  return (
    <>
      <footer
        className={`z-20 w-full flex flex-col sm:flex-row justify-center sm:justify-around items-center sm:items-start pb-3 ${
          theme === "dark"
            ? `bg-[#27272A] text-white`
            : `bg-[#0f0f0f] text-white`
        } `}
      >
        <div className="h-44 pt-10 ml-0 sm:ml-[5rem]">
          <h1 className="font-bold text-lg sm:text-xl text-center sm:text-left">
            {" "}
            <span className="text-4xl">K</span>onnectSquare LLP
          </h1>
          <h3 className="mt-3 text-sm sm:text-sm mx-5 sm:mx-0">
            KonnectSquare revolutionizes talent crowd-sourcing in India
            {!isfooterScreen && <br />} by leveraging innovation and technology
            to match the {!isfooterScreen && <br />}perfect talents with the
            right companies.
          </h3>
          {!isSmallfooterScreen && (
            <p className="mt-4 mb-2 mx-5 sm:mx-0" style={{ fontSize: "12px" }}>
              {" "}
              Copyright © {new Date().getFullYear()} KonnectSquare LLP
            </p>
          )}
        </div>

        <div className="h-fit sm:h-44 pt-5 sm:pt-10 flex flex-col">
          {/* {!isSmallScreen && <h1 className="font-bold text-2xl text-blue-600">Social Links</h1>} */}
          <div className="flex flex-row gap-9 sm:gap-5 mt-5">
            <Link
              isExternal
              aria-label="Twitter"
              href={"https://www.facebook.com/konnectsquare"}
            >
              <FaFacebook className="" />
            </Link>
            <Link
              isExternal
              aria-label="Discord"
              href={"https://wa.me/+919699033291"}
            >
              <WhatsappLogo className="text-2xl" />
            </Link>
            <Link
              isExternal
              href={"https://www.linkedin.com/company/konnectsquare"}
              aria-label="Github"
            >
              <LinkdinLogo className="text-2xl" />
            </Link>
            <Link
              isExternal
              href={"https://www.instagram.com/konnectsquare"}
              aria-label="Github"
            >
              <img
                style={{
                  width: "22px",
                  height: "22px",
                  background: "transparent",
                }}
                src="/insta.png"
                alt=""
              />
            </Link>
            <Link
              isExternal
              href={"https://t.me/+S7ylBNxB5WRjNTJl"}
              aria-label="Github"
            >
              <TelegramLogo className="text-2xl " />
            </Link>
          </div>
        </div>
        <div className="h-44 pt-2 sm:pt-5 w-10/12 sm:w-fit">
          {/* {!isSmallScreen && <h1 className="font-bold text-2xl text-blue-600">Quick Links</h1>} */}
          <div className=" flex flex-col gap-3 mt-0 ">
            <NextLink href="/about">
              <span>About us</span>
            </NextLink>
            <NextLink href="/hirewithus">
              <span>Hire with us</span>
            </NextLink>
            <NextLink href="/faqs">
              <span>FAQs</span>
            </NextLink>
            <NextLink href="/tnc">
              <span>Terms of Use</span>
            </NextLink>
            <NextLink href="/privacy">
              <span>Privacy Policy</span>
            </NextLink>
          </div>
        </div>
        <div className="w-11/12 sm:w-1/5   pt-10 sm:pt-4">
          <h1 className="font-bold text-2xl text-center ">Contact us</h1>
          <form className="mt-2" onSubmit={handleSubmit(onSubmit)}>
            <Input
            className="bg-white"
              type="text"
              variant="underlined"
              placeholder="Enter your Fullname"
              {...register("fullName", { required: true })}
            />
            <Textarea
              className="mt-2 bg-white"
              variant="underlined"
              placeholder="Enter message"
              {...register("message", { required: true })}
            />
            <div className="mt-1 mb-2 text-center ">
              <Button variant="faded" type="submit">
                Submit
              </Button>
            </div>
          </form>
        </div>
      </footer>
    </>
  );
};
