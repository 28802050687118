export type SiteConfig = typeof siteConfig;

export const siteConfig = {
	name: "KonnectSquare",
	description: "Find your dream job",
	url: "https://konnectsquare.com",
	navItems: [
		{
			label: "About us",
			href: "/about",
		},
		{
			label: "Jobs",
			href: "/",
		},
		
		// {
		// 	label: "View Job",
		// 	href: "/",
		// },
		// {
		// 	label: "About",
		// 	href: "/about",
		// },
		// {
		// 	label: "Profile",
		// 	href: "/profile"
		// },
		// {
		// 	label: "Referrals",
		// 	href: "/creditCount"
		// },
		// {
		// 	label: "Post Job",
		// 	href: "/postjob"
		// }
	],
	navMenuItems: [
		{
			label: "Jobs",
			href: "/",
		},
		{
			label: "About Us",
			href: "/about",
		},
		{
			label: "Terms of Use",
			href: "/tnc"
		},
		{
			label: "Privacy Policy",
			href: "/privacy"
		},

	],

	userLogin: [
		{
			label: "About Us",
			href: "/about",
		},
		{
			label: "Jobs",
			href: "/",
		},
		// {
		// 	label: "Profile",
		// 	href: "/profile"
		// },
		// {
		// 	label: "Referrals",
		// 	href: "/creditCount"
		// }
	],
	adminLogin: [
		{
			label: "Jobs",
			href: "/",
		},
		{
			label: "About Us",
			href: "/about",
		},
		// {
		// 	label: "Profile",
		// 	href: "/profile"
		// },
		{
			label: "PostJob",
			href: "/postjob"
		},
		// {
		// 	label: "Referrals",
		// 	href: "/creditCount"
		// },
		{
			label: "View Application",
			href: "/viewapplication"
		}
	],
	links: {
		github: "https://github.com/nextui-org/nextui",
		twitter: "https://twitter.com/getnextui",
		docs: "https://nextui.org",
		discord: "https://discord.gg/9b6yyZKmH4",
		sponsor: "https://patreon.com/jrgarciadev"
	},
};
