import(/* webpackMode: "eager", webpackExports: ["Providers"] */ "/app/app/providers.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Footer"] */ "/app/components/footer.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Navbar"] */ "/app/components/navbar.tsx");
;
import(/* webpackMode: "eager" */ "/app/node_modules/keen-slider/keen-slider.min.css");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/app/styles/globals.css");
;
import(/* webpackMode: "eager" */ "/app/node_modules/firebaseui/dist/firebaseui.css");
