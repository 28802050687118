/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable @next/next/no-img-element */
"use client";

import {
  Navbar as NextUINavbar,
  NavbarContent,
  NavbarMenu,
  NavbarMenuToggle,
  NavbarBrand,
  NavbarMenuItem,
} from "@nextui-org/navbar";
import { Button } from "@nextui-org/button";
import { Kbd } from "@nextui-org/kbd";
import { Link } from "@nextui-org/link";
import { Input } from "@nextui-org/input";
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  useDisclosure,
  Checkbox,
} from "@nextui-org/react";
import { useRouter } from "next/navigation";
import { siteConfig } from "@/config/site";
import NextLink from "next/link";
import { ThemeSwitch } from "@/components/theme-switch";
import {
  SearchIcon,
  LOGIN,
  MailIcon,
  LockIcon,
  LogoutLogo,
} from "@/components/icons"; // Keep previous imports
import { FaUser, FaUsers } from "react-icons/fa"; // Import react-icons

import { Logo } from "@/components/icons";
import { useEffect, useState, useRef } from "react";
import RoleCheck from "@/utils/utils";
import Cookies from "js-cookie";
import { useTheme } from "next-themes";

export const Navbar = () => {
  const router = useRouter();
  const { theme, setTheme } = useTheme();
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const { isOpen, onOpen, onOpenChange } = useDisclosure();
  const [role, setRole] = useState({
    isAdmin: false,
    isUser: false,
    isLoggedIn: false,
    isSubAdmin: false,
  });

  const mobileMenuRef = useRef<HTMLDivElement>(null); // Reference for mobile menu
  const dropdownRef = useRef<HTMLDivElement>(null); // Reference for dropdown menu

  const [mounted, setMounted] = useState(false);

  // Hydration fix: only run on client-side
  useEffect(() => {
    setMounted(true); // Component mounted on client-side
  }, []);

  useEffect(() => {
    if (theme === "dark" || theme === "light") return; // theme already set
    setTheme("light"); // Default theme on first load
  }, [theme, setTheme]);

  useEffect(() => {
    (async () => {
      let { isAdmin, isUser, isLoggedIn, isSubAdmin } = await RoleCheck();
      setRole({ isAdmin, isUser, isLoggedIn, isSubAdmin });
    })();
  }, []);

  // Toggle dropdown visibility
  const toggleDropdown = () => setDropdownOpen(!isDropdownOpen);

  // Close the dropdown and mobile menu if click is outside
  const handleClickOutside = (event: { target: any }) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target) &&
      mobileMenuRef.current &&
      !mobileMenuRef.current.contains(event.target)
    ) {
      setDropdownOpen(false); // Close the dropdown
      setMobileMenuOpen(false); // Close the mobile menu
    }
  };

  useEffect(() => {
    // Add event listener when component mounts
    document.addEventListener("mousedown", handleClickOutside);

    // Clean up the event listener when component unmounts
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  if (!mounted) return null; // Prevent rendering on SSR

  return (
    <>
      <NextUINavbar
        maxWidth="xl"
        className="bg-white border-gray-200 px-0 py-2.5 dark:bg-gray-800"
        style={{
          background: "rgba(255, 255, 255, 0.3)",
          backdropFilter: "blur(10px)",
          borderBottomLeftRadius: "8px",
          borderBottomRightRadius: "8px",
          boxShadow:
            "0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08)",
          position: "sticky",
          top: 0,
          zIndex: 1000,
        }}
      >
        <div className="flex flex-wrap justify-between items-center w-full gap-2">
          {/* Brand Logo */}
          <NavbarBrand as="li" className="gap-[15rem] max-w-fit">
            <NextLink className="flex items-center gap-1" href="/">
              {theme === "dark" ? (
                <img
                  src="/white.png"
                  className="h-10 w-48 cursor-pointer"
                  alt="Logo"
                />
              ) : (
                <img
                  src="/black.png"
                  className="h-10 w-48 cursor-pointer"
                  alt="Logo"
                />
              )}
            </NextLink>

            {/* Navbar Menu */}
            <div
              ref={mobileMenuRef}
              className={`${
                isMobileMenuOpen
                  ? "absolute top-16 left-0 w-full z-50 bg-white dark:bg-gray-800"
                  : "hidden"
              } lg:flex lg:w-auto `}
              id="mobile-menu"
            >
              <ul className="flex justify-center items-center gap-4 mt-4 font-medium lg:flex-row lg:mt-0 w-full">
                {/* Default Navigation */}
                {!role?.isLoggedIn &&
                  !role?.isUser &&
                  !role?.isAdmin &&
                  !role?.isSubAdmin &&
                  siteConfig.navItems.map((item, index) => (
                    <li key={`${item.label}-${index}`} style={{ marginLeft: "4rem" }}>
                      <NextLink
                        href={item.href}
                        onClick={() => setMobileMenuOpen(false)}
                        className="block py-2 px-4 text-gray-700 hover:bg-gray-100 lg:hover:bg-transparent lg:hover:text-primary-700 dark:text-white dark:hover:bg-gray-700 dark:hover:primary-700 lg:dark:hover:bg-transparent"
                      >
                        {item.label}
                      </NextLink>
                    </li>
                  ))}
                {/* User Navigation */}
                {role?.isLoggedIn &&
                  role?.isUser &&
                  !role?.isAdmin &&
                  !role?.isSubAdmin &&
                  siteConfig.userLogin.map((item, index) => (
                    <li key={`${item.label}-${index}`} style={{ marginLeft: "4rem" }}>
                      <NextLink
                        onClick={() => setMobileMenuOpen(false)}
                        href={item.href}
                        className="block py-2 px-4 text-gray-700 hover:bg-gray-100 lg:hover:bg-transparent lg:hover:text-primary-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent"
                      >
                        {item.label}
                      </NextLink>
                    </li>
                  ))}
                {/* Admin Navigation */}
                {(role?.isAdmin || role?.isSubAdmin) &&
                  siteConfig.adminLogin.map((item, index) => (
                    <li key={`${item.label}-${index}`}>
                      <NextLink
                        onClick={() => setMobileMenuOpen(false)}
                        href={item.href}
                        className="block py-2 px-4 text-gray-700 hover:bg-gray-100 lg:hover:bg-transparent lg:hover:text-primary-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent"
                      >
                        {item.label}
                      </NextLink>
                    </li>
                  ))}
              </ul>
            </div>
          </NavbarBrand>

          {/* Right Section with Login/Logout Buttons */}
          <div className="ml-8 flex items-center gap-4">
            <ThemeSwitch />

            {/* Profile Dropdown */}
            {role?.isLoggedIn && (
              <div className="relative" ref={dropdownRef}>
                <button
                  onClick={toggleDropdown}
                  className="text-gray-800 dark:text-white"
                >
                  <FaUser className="text-lg" />
                </button>
                {isDropdownOpen && (
                  <div className="absolute right-0 mt-2 w-48 bg-white dark:bg-gray-700 text-gray-800 dark:text-white shadow-lg rounded-lg">
                    <NextLink href="/profile" passHref>
                      <div className="px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 flex items-center">
                        <FaUser className="mr-2 text-lg" />
                        Profile
                      </div>
                    </NextLink>
                    <NextLink href="/creditCount" passHref>
                      <div className="px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 flex items-center">
                        <FaUsers className="mr-2 text-lg" />
                        Referrals
                      </div>
                    </NextLink>
                    <div
                      className="px-4 py-2 text-red-500 cursor-pointer hover:bg-gray-100 dark:hover:bg-gray-600 flex items-center"
                      onClick={() => {
                        Cookies.remove("cat");
                        router.push("/login");
                        setRole({
                          isAdmin: false,
                          isUser: false,
                          isLoggedIn: false,
                          isSubAdmin: false,
                        });
                      }}
                    >
                      <LogoutLogo className="mr-2" />
                      Log Out
                    </div>
                  </div>
                )}
              </div>
            )}

            {!role?.isLoggedIn && (
              <Button
                className="text-sm font-normal text-gray-800 bg-gray-100 hover:bg-gray-200 rounded-lg px-4 py-2 dark:text-white dark:bg-gray-700 dark:hover:bg-gray-600"
                onClick={() => {
                  router.push("/login");
                  window.location.pathname = "/login";
                }}
              >
                <LOGIN className="mr-2" />
                Log In
              </Button>
            )}

            {/* Hamburger Button */}
            <button
              data-collapse-toggle="mobile-menu"
              className="inline-flex items-center p-2 text-sm text-gray-500 rounded-lg lg:hidden hover:bg-gray-100 dark:hover:bg-gray-700"
              aria-controls="mobile-menu"
              aria-expanded={isMobileMenuOpen}
              onClick={() => setMobileMenuOpen((prev) => !prev)}
            >
              <span className="sr-only">Open main menu</span>
              <svg
                className="w-6 h-6"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </button>
          </div>
        </div>
      </NextUINavbar>

      {/* Modal */}
      <Modal isOpen={isOpen} onOpenChange={onOpenChange} placement="top-center">
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1">Log in</ModalHeader>
              <ModalBody>
                <Input
                  autoFocus
                  endContent={
                    <MailIcon className="text-2xl text-default-400 pointer-events-none flex-shrink-0" />
                  }
                  label="Email"
                  placeholder="Enter your email"
                  variant="bordered"
                />
                <Input
                  endContent={
                    <LockIcon className="text-2xl text-default-400 pointer-events-none flex-shrink-0" />
                  }
                  label="Password"
                  placeholder="Enter your password"
                  type="password"
                  variant="bordered"
                />
                <div className="flex py-2 px-1 justify-between">
                  <Checkbox
                    classNames={{
                      label: "text-small",
                    }}
                  >
                    Remember me
                  </Checkbox>
                  <Link color="primary" href="#" size="sm">
                    Forgot password?
                  </Link>
                </div>
              </ModalBody>
              <ModalFooter>
                <Button color="danger" variant="flat" onPress={onClose}>
                  Close
                </Button>
                <Button color="primary" onPress={onClose}>
                  Sign in
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
};
